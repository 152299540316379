.trendingNews h3 {
  svg {
    margin-right: 8px;

    path {
      fill: #000;
    }
  }
}

.trendingList {
  article {
    margin-top: 12px;

    &:last-child .desc {
      border-bottom: none;
    }
  }

  .num {
    min-width: 24px;
    font-size: 21px;
    line-height: 28px;
  }

  .desc {
    padding: 0px 10% 16px 0;
    width: calc(100% - 24px);
    border-bottom: 1px solid var(--border-light);
  }

  h4 {
    a {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
}

/***** Responsive CSS Start ******/

@media (min-width: 992px) and (max-width: 1199px) {
  .trendingList {
    .desc {
      padding: 0px 7% 16px 0;
    }
  }
}

@media (max-width: 767px) {
  .trendingList {
    // article {
    //   margin: 12px 16px;
    // }

    .desc {
      padding: 0px 0px 16px 0px;
    }
  }
}