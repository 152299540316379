.downloadApp {
  padding: 20px;
  min-height: 58px;
  border-radius: 16px;
  border: 1px solid var(--border-light);

  .info {
    z-index: 1;
  }

  .icon {
    width: 110px;
  }
}

.isNotFirst {
  margin-top: 2px;
}

.banHgtFrst {
  >a>span {
    min-height: 58px !important;
  }
}

.appImage {
  width: 37%;
  top: 0%;
  right: 21px;
  @include transform(translateX(9%));
  z-index: 0;
}

.staticBanner {
  border-radius: 0px;
}

/***** Responsive CSS Start ******/

@media (min-width: 1400px) {
  .notFoundBanner {
    width: 26%;
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {}

@media (min-width: 992px) and (max-width: 1199px) {}

@media (min-width: 768px) and (max-width: 991px) {}

@media (max-width: 767px) {
  .downloadApp {
    min-height: 0px;

    .icon {
      width: 122px;
    }
  }

  .staticBanner {
    border-radius: 0px;
  }
}