.badge { padding: 0px 6px; display: inline-block; font-size: 11px; line-height: 16px; border-radius: 2em; color: #fff; text-transform: uppercase; max-width: 100%; text-align: center; white-space: nowrap; vertical-align: baseline;
  &.bg-secondary  { background: $border-color !important; }
  &.bg-primary  {
    [data-light-mode=false] & {
      background: transparent !important;
      border: 1px solid var(--border-input);
    }
    background: var(--theme-light) !important;
    color: var(--theme-color-light);
  }
  &.bg-danger  { background: $danger !important; color: #fff;
      &.live::before { @extend %after-before; margin: 0px 7px 0px 4px; position: relative; display: inline-block; width: 4px; height: 4px; background: #fff; vertical-align: middle; box-shadow: 0px 0px 0px 4px rgba(255,255,255,0.3); border-radius: 50%; }
      a:hover { color: inherit}
  }
  span { display: inline-block; vertical-align: middle; }
  &.video-badge { min-width: 48px;
      &::before { @extend %after-before; margin: -1px 3px 0px 0px; position: relative; display: inline-block; width: 10px; height: 10px; background: url(../../images/icon/play-icon.svg) no-repeat center center / cover; vertical-align: middle; 
        [data-light-mode=false] & {
          filter: brightness(0) invert(1) opacity(0.9);
        }
      }
  }
}