/* Accordion CSS */
.accordion-item { margin-bottom: 16px; background: transparent; border: none; font-size: 14px; line-height: 24px; font-weight: 600;
  .accordion-header { font-size: inherit; line-height: inherit; font-weight: inherit; }
  .accordion-button { padding: 8px; background: var(--light-mode-bg); font-size: inherit; font-weight: inherit; color: var(--font-color); border: 1px solid var(--light-bg); @include box-shadow(none); border-radius: 8px;
    &:not(.collapsed) { background: var(--theme-light); 
        &::after { @include transform(rotate(90deg));}
    }
    &.collapsed { border-radius: 8px;}
    .icon { margin-right: 8px; width: 28px; height: 28px; background: $theme-medium; border-radius: 4px; }
    &::after { width: 24px; height: 24px; background: url(../../images/icon/down-arrow.svg) no-repeat center center / cover; filter: grayscale(100%);
      [data-light-mode="false"] & {
        filter: brightness(0) invert(1);
    }
    }
  }
  &:last-of-type .accordion-button,
  &:last-of-type .accordion-button.collapsed,
  &:first-of-type .accordion-button { border-radius: 0px;}
}
.accordion-body { margin-top: -8px; padding: 12px 0 8px; background: var(--light-bg);
  button { padding: 8px 8px 8px 10px; width: 100%; font-size: inherit; line-height: inherit; font-weight: inherit; color: inherit; text-align: left; }
}

@media (max-width: 575px) {
  .accordion-item { margin-bottom: 12px; font-size: 13px; line-height: 22px; }
}