.table-responsive {
  margin-bottom: 20px;
  overflow-x: auto;
  scrollbar-width: thin;
  scrollbar-color: var(--border-light) var(--light-mode-bg);
  border-radius: 12px;
  border: 1px solid var(--border-light);
}

.table-scroller {
  margin-bottom: 16px;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  border: 1px solid var(--border-light);
  border-radius: 12px !important;
  scrollbar-width: thin;
  scrollbar-color: var(--border-light) var(--light-mode-bg);
}

table {
  margin: 0px;
  width: 100%;
  border-collapse: collapse;
  border: none;
  th,
  td {
    padding: 16px 14px;
    height: 42px;
    text-align: center;
    &:first-child {
      text-align: left;
    }
    &:last-child {
      text-align: center;
    }
  }
  th {
    background: #efefef;
    text-transform: capitalize;
    font-weight: 600;
    text-align: center;
    &:first-child {
      border-radius: 12px 0 0 0;
      text-align: left;
    }
    &:last-child {
      border-radius: 0 12px 0 0;
    }
    [data-light-mode='false'] & {
      background: var(--miniScoreCard-btn);
    }
  }
  tr:not(:last-child) td {
    border-bottom: 1px dashed var(--border-light);
  }
  tr:last-child td {
    &:first-child {
      border-radius: 0 0 0 12px;
    }
    &:last-child {
      border-radius: 0 0 12px 0;
    }
  }
  td {
    background: var(--light-mode-bg);
  }
  .highlight td {
    background: var(--theme-light);
  }
  .icon-img {
    margin-right: 10px;
    display: inline-block;
    width: 36px;
    height: 36px;
    background: #fff;
    border-radius: 10px;
    overflow: hidden;
    vertical-align: middle;
  }
  &.numbered {
    th:first-child {
      width: 51px;
    }
  }
  &.bordered {
    border-spacing: 0;
    border-radius: 8px;
    overflow: hidden;
    td {
      border-bottom: 1px solid var(--theme-bg);
    }
  }
}
/***** Responsive CSS Start ******/

@media (max-width: 991px) {
  table {
    margin: -4px 0px 0px;
    width: 100%;
    th,
    td {
      padding: 4px 10px;
      height: 40px;
      &:first-child {
        border-radius: 4px 0 0 0;
      }
      &:last-child {
        border-radius: 0 4px 0 0;
      }
    }
    .icon-img {
      width: 24px;
      height: 24px;
      border-radius: 4px;
    }
  }
}

@media (max-width: 575px) {
  .table-responsive,
  .table-scroller {
    margin: 0 0 12px;
    border-radius: 0;
    overflow-x: auto;
    border-radius: 6px;
    border: 1px solid var(--border-light);
  }
  table {
    margin: -3px 0px 0px;
    width: 100%;
    th,
    td {
      padding: 10px 6px;
      height: 36px;
      &:first-child {
        border-radius: 0;
        padding-left: 10px;
      }
      &:last-child {
        border-radius: 0;
        padding-right: 10px;
      }
    }
    tr:last-child td {
      &:first-child {
        border-radius: 0;
      }
      &:last-child {
        border-radius: 0;
      }
    }
    .icon-img {
      width: 23px;
      height: 23px;
      border-radius: 3px;
    }
    &.bordered {
      border-radius: 0px;
    }
  }
}
