.toast-b {
  border-radius: 2em;
  position: fixed;
  top: 60px;
  right: 24px;
  z-index: 9999;
  padding: 10px 16px;
  color: #fff;
  width: 340px;
  max-width: 90%;
  overflow: hidden;
  &::before {
    @extend %after-before;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
  }
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  &.danger {
    background: $danger-bg;
    color: $danger;
    polygon {
      fill: $danger;
    }
  }
  &.success {
    background: $success-bg;
    color: $success-dark;
    polygon {
      fill: $success-dark;
    }
  }
  > * {
    position: relative;
    z-index: 1;
  }
  .btnClose {
    padding: 2px;
    font-weight: 700;
    border: 1px solid;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    border: none;
    
    &::after {
      display: none;
    }
    svg {
      width: 100%;
      height: 100%;
      display: block;
    }
  }
}
