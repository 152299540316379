@media (max-width: 767px) {
  .mobileMenu { position: fixed; left: 0; bottom: 0; width: 100vw; background: var(--light-mode-bg); z-index: 6; @include box-shadow(2px 0px 8px rgba(0,0,0,0.1));
    li { padding: 0px 4px; flex-grow: 1; min-width: 66px; }
    svg { display: block; margin: 0px auto 2px; width: 24px; height: 24px; }
    a, button { padding: 8px 0px 2px; display: block; font-size: 11px; height: 100%; font-weight: 700; color: var(--font-light);
      &.active { background: linear-gradient(180deg, rgba(151, 28, 75,0.1) 0%, rgba(249, 249, 249,0.1) 100%);; color: var(--theme-color-orig);
        path,polygon { fill: var(--theme-color-orig); }
      }
      path,polygon { fill: var(--font-light); }
     }
     a:hover { color: var(--theme-color-orig);
      path,polygon { fill: var(--theme-color-orig); }
    }
  }
}

@media (max-width: 350px) {
  .mobileMenu {
    li { min-width: 60px;
    }
  }
}