.modal-content { background: var(--light-mode-bg); border-radius: 16px; border: none; }
.btn-close { width: 22px; height: 22px; background: url(../../images/input/close-icon.svg) no-repeat ;
  // [data-light-mode=false] & {
  //   filter: brightness(0) invert(1) opacity(0.4);
  // }
}
.modal-header .btn-close { padding: 0; margin: 0 0 0 127px; }
.modal-dialog { width: 850px; max-width: 94%; 
    .modal-medium & { width: 632px;  }
    .modal-small & { width: 445px;  }
}

@media (max-width: 767px) {
  .modal-content { border-radius: 8px; border: none; }
  .modal-header .btn-close { margin: 0 0 0 48px; }
}