.hd-search-ot {
  min-width: 48px;
  min-height: 32px;
}

.searchItem {
  position: relative;
  z-index: 3;

  .searchBtn {
    width: 32px;
  }

  button {
    position: relative;
    z-index: 1;
  }

  svg {
    position: relative;
  }
}

.searchBox {
  display: flex;
}

.hd-searchGroup {
  width: 232px;

  .hs-searchControl {
    padding: 6px 46px 6px 16px;
    line-height: 26px;
    border-radius: 2em;
    border: 1px solid var(--border-input);
  }

  .icon {
    width: 30px;
    position: absolute;
    right: 5px;
    top: 50%;
    @include transform(translateY(-50%));
  }

  .hs-searchIcon {
    left: 12px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .hd-searchGroup {
    width: 200px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .hd-searchGroup {
    width: 180px;
  }
}

@media (max-width: 767px) {
  .searchBox form { 
    width: 100%;
  }
  .hd-searchGroup {
    width: 100%;
    .icon {
      width: 24px;
    }
  }
}

@media (max-width: 575px) {
  .searchItem {
    .s-overlay {
      top: 50px;
    }
  }

  // .searchBox {
  //   position: fixed;
  //   top: 80px;
  //   right: 8px;
  // }

}