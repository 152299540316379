/******* Common Element CSS Start ******/
[data-light-mode="true"] {
    --font-color: #414141;
    --font-color-dark: #d0d0d0;
    --font-color-light: #9C9C9C;
    --font-secondary: #323842;
    --theme-bg: #f5f5f5;
    --light-mode-bg: #fff;
    --theme-color: #F25824;
    --theme-color-secondary: #252775;
    --theme-color-light:#F25824;
    --theme-color-header:#F25824;
    --theme-color-bright: #F25824;
    --theme-color-medium: #F25824;
    --theme-dark2: #740f35;
    --theme-light: #faede9;
    --theme-light-dark: #faede9;
    --font-dark: #656565;
    --border-color: #e1e1e1;
    --border-medium: #e0e0e0;
    --font-light: #959595;
    --light: #EEEEEE;
    --light-bg: #f5f5f5;
    --light-bg-commentary: #FEEEE9;
    --theme-medium: #F25824;
    --border-dark2: #dcd7d7;
    --border-light: #dadada;
    --border-dark: #e7e7e7;
    --border-input: #d9d9d9;
    --light-color: #fff;
    --light-color-medium: #fff;
    --theme-medium2: #ffede6;
    --border-light2: #d8d8d8;
    --font-light2: #373737;
    --background-miniScoreCard-gradient: inherit;
    --bkg-miniScoreCard: #FFFFFF;
    --miniScoreCard-btn: #F9F9F9;
    --danger: #DA3400;
    --white: #fff;
    --theme-color-orig: #F25824;
    --dark-mode: #EEEEEE;
    --ball-by-ball: #0057FF;
    --font-color-button: #F25824;
    --light-mode-secondary-bg: #f8f8f8;
    --olympics-bg: #ebeaea;
    --olympics-reads: #252775;
    --article-text-color: #888888;
    --win-percentage-blue: #262774;
    --win-percentage-head: #262774;
    --dropDown-color: #fff;
    --hockey-venue: #FFFCF9;
    --hockey-title : #202B5A;
    --black-white : #000;
}

[data-light-mode="false"] {
    --font-color: #fff;
    --font-color-dark: #d2d5d9;
    --font-color-light: #fff;
    --font-secondary: #d2d5d9;
    --theme-bg: #111316;
    --light-mode-bg: #1A1917;
    --theme-color: #1A1917;
    --theme-color-secondary: #252775;
    --theme-color-light: #F25824;
    --theme-color-header: #2e2e2e;
    --theme-color-bright: #F25824;
    --theme-color-medium: #F25824;
    --theme-dark2: #a7acb4;
    --font-dark: #d2d5d9;
    --border-color: #a7acb4;
    --border-medium: #323842;
    --font-light: #a7acb4;
    --light: #1A1917;
    --light-bg: #121110;
    --light-bg-commentary: #121110;
    --theme-medium: #F25824;
    --theme-light: #323842;
    --theme-light-dark: #0d121a;
    --border-light: #2B2827;
    --border-dark2: #0d121a;
    --border-dark: #e7e7e7;
    --border-input: #50555d;
    --light-color: #0d121a;
    --light-color-medium: #414141;
    --theme-medium2: #a7acb4;
    --border-light2: #50555d;
    --font-light2: #373737;
    --bkg-miniScoreCard: #1A1917;
    --miniScoreCard-btn: #221F1E;
    --danger: #ef4a40;
    --white: #fff;
    --theme-color-orig: #F25824;
    --dark-mode: #656565;
    --ball-by-ball: #fff;
    --font-color-button: #FFFFFF;
    //Extra Color
    --bs-dark-rgb: 210, 213, 217;
    --bs-secondary-rgb: 117, 122, 130;
    --bs-info-rgb: 13, 18, 26;
    --bs-light-rgb: 13, 18, 26;
    --bs-primary-rgb: 210, 213, 217;
    --bs-white-rgb: 35, 39, 46;
    --background-miniScoreCard-gradient: #121110;
    --light-mode-secondary-bg: #414141;
    --olympics-bg: #221F1E;
    --olympics-reads: #2E2E2E;
    --article-text-color: #c1bdbd;
    --win-percentage-blue: #5557d7;
    --win-percentage-head: #fff;
    --dropDown-color: #000000;
    --hockey-venue: #323842;
    --hockey-title : #d2d5d9;
    --black-white : #fff;
}

:root {
    scroll-behavior: initial;
}

* {
    margin: 0px;
    padding: 0px;
    outline: none;
}

body {
    background: var(--theme-bg);
    font: 500 14px/20px $theme-font;
    color: var(--font-color);
    -webkit-text-size-adjust: none;
    font-display: swap;
}

body.mobile-theme {
    background: #ffffff !important;
  }

  
ul,
ol {
    padding: 0;
    list-style: none;
}

a {
    color: inherit;
    text-decoration: none;
    outline: none;
    cursor: pointer;
}

.ct-instagram-embed {
    > iframe {
        width: 420px;
        height: 236px;
        margin-top: 12px;
    }
}

a:focus,
a:active,
a:visited,
a:hover {
    text-decoration: none;
    outline: none;
}

a:hover {
    color: var(--theme-color-light);
}

h1 {
    margin: 0 0 16px;
    font-size: 32px;
    line-height: 44px;
    font-weight: 700;
}

h2 {
    margin: 0 0 16px;
    font-size: 30px;
    //line-height: 40px;
    font-weight: 700;
}

h3 {
    margin: 0 0 16px;
    font-size: 24px;
    line-height: 32px;

    &.small-head {
        font-size: 18px;
        line-height: 24px;
        font-weight: 700;
    }
}

h4{
    margin: 20px 0 16px!important;
    font-size: 21px;
    color: #392E32;
    font-weight: 700 !important;

    [data-light-mode="false"] & {
        color: var(--font-dark);
    }

    &.small-head {
        font-size: 15px;
        line-height: 20px;
        font-weight: 600;
    }
}

.h4 {
    margin: 0px 0 16px!important;
    font-size: 21px;
    color: #392E32;
    font-weight: 700 !important;

    [data-light-mode="false"] & {
        color: var(--font-dark);
    }

    &.small-head {
        font-size: 15px;
        line-height: 20px;
        font-weight: 600;
    }
}

h5 {
    margin: 0 0 16px;
    font-size: 18px;
    line-height: 27px;
    font-weight: 700;

    &.small-head {
        font-size: 15px;
        line-height: 20px;
    }
}

h6 {
    margin: 20px 0 16px;
    font-size: 18px;
    line-height: 27px;
    font-weight: 700;
}

.redSix {
    color: #F2F2F2!important;
    background-color: #E50000!important;
    border-color:#E50000!important;
  }

.line-title {
    margin-bottom: 16px;

    >* {
        margin: 0;
        padding: 0px 20px;
        max-width: 88%;
        border: 3px solid var(--font-dark);

        &::after,
        &::before {
            @extend %after-before;
            top: 50%;
            width: 100vw;
            height: 2px;
            background: var(--light);
        }

        &::after {
            left: calc(100% + 20px);
        }

        &::before {
            right: calc(100% + 20px);
        }
    }

    button {
        margin-left: 6px;
        width: 24px;
        height: 24px;
        border: 1px solid $border-dark2;
        border-radius: 50%;

        path {
            fill: $border-color;
        }

        &.active {
            background: $theme-color;
            border-color: $theme-color;

            path {
                fill: #fff;
            }
        }
    }

    span {
        text-overflow: ellipsis;
    }

    a:hover {
        color: var(--font-color);
    }
}

.big-text {
    font-size: 16px;
    line-height: 24px;
}

.white-text-color {
    color: var(--white);
}

.lh-15 {
    line-height: 1.5;
}

.orig-theme {
    color: var(--theme-color-orig);

    [data-light-mode="false"] & {
        color: var(--white);
    }
}

small,
.small-text {
    font-size: 13px;
    line-height: 18px;
}

.xsmall-text {
    font-size: 12px;
    line-height: 18px;
}

.font-semi {
    font-weight: 600;
}

.ot-color-darkmode {
    color: var(--theme-color-light);
}

.font-bold,
b {
    font-weight: 700;
}

.font-ebold {
    font-weight: 800;
}

.font-theme {
    color : var(--font-secondary)
}

img {
    max-width: 100%;
    border: 0px;
}

.block-img>span {
    display: block !important;
}

.btn-link,
.btn-check:focus+.btn,
.form-check-input,
button,
.btn,
button[type="button"] {
    text-decoration: none;

    &:focus {
        outline: none;
        @include box-shadow(none);
    }
}

.bg-playerCard {
    background: transparent !important;
}

.btn-link {
    padding: 0;
    color: inherit;
    font-size: inherit;
    line-height: inherit;
    font-weight: inherit;
}

hr {
    background-color: var(--light);
    opacity: 1;
}

.offcanvas-backdrop {
    background: $font-color;

    .show {
        opacity: 0.39;
    }
}

.vh-50 {
    height: 50vh;
}

.mb-01 {
    margin-bottom: 2px;
}

.mt-01 {
    margin-top: 2px;
}
.my-01 {
    margin-top: 2px !important;
    margin-bottom: 2px !important;
}

.ml-01 {
    margin-left: 15px !important;
}

.mr-01 {
    margin-right: 8px;
}

.theme-text {
    color: var(--theme-color-medium);
}
.theme-text-bright {
    color: var(--theme-color-bright);
    [data-light-mode='false'] & {
        color: var(--font-color);
    }
}

.medium-text {
    color: $border-color;
}

.light-text {
    color: $font-light;
}

.text-black-white {
    color: var(--black-white);
}

.success-text {
    color: $success;
}

.danger-text {
    color: $danger;
}

.ct-border {
    border: 1px solid;
}

.border-info {
    border-color: var(--theme-light-dark) !important;
}

p:empty {
    margin: 0 !important;
}

/* Button CSS */
.theme-btn {
    padding: 10px 16px;
    display: inline-block;
    background: #FFFBF9;
    font-size: 15px;
    line-height: 22px;
    color: var(--font-color-button);
    text-align: center;
    font-weight: 700;
    border: 1px solid var(--border-light);
    border-radius: 12px;

    [data-light-mode="false"] & {
        background: var(--background-miniScoreCard-gradient)
    }

    svg {
        width: 15px;
        height: 20px;
        margin-left: 6px;
        margin-top: -4px;
    }

    &.iconBtn {
        display: inline-flex;
        @include justify-content(center);
        @include align-items(center);
    }

    &.iconBtn::after {
        margin-left: 3px;
        @extend %after-before;
        position: relative;
        width: 24px;
        height: 24px;
        background: url(./../images/icon/right-white-arrow.svg) no-repeat center center / cover;
    }

    &:hover,
    &:focus {
        background: $theme-dark;
        color: #fff;
        border-color: $theme-dark;
        @include box-shadow(none);

        &.iconBtn::after {
            margin-left: 5px;
            filter: brightness(0) invert(1);
        }
    }

    &.full-btn {
        width: 100%;
    }

    &.outline-btn {
        background: transparent;
        color: var(--theme-color-light);
        border-color: var(--theme-medium);

        &:hover {
            background: $theme-color;
            color: #fff;
            border-color: $theme-color;
        }

        path {
            stroke: var(--theme-color-light);
        }

        &.outline-light {
            border-color: var(--border-light);
            color: var(--font-color);

            &:hover {
                background: var(--border-light);
                color: var(--font-color);
                border-color: var(--border-light);
            }
        }
    }

    &.nobg-btn {
        padding: 0;
        background: transparent;
        color: var(--theme-color-medium);
        border: none;

        &.iconBtn::after {
            margin-left: 7px;
            filter: none;
        }
    }

    &.small-btn {
        padding: 6px 16px;
    }

    &.xsmall-btn {
        padding: 4px 16px;
    }

    &.light-btn {
        background: #fff;
        border-color: #fff;
        color: $theme-color;

        &:hover,
        &:focus {
            color: $theme-color;
        }
    }

    &.secondary-btn {
        background: var(--light-bg);
        border-color: var(--light-bg);
        color: var(--font-color);

        &:hover,
        &:focus {
            background: var(--light);
            border-color: var(--light);
            color: var(--font-color);
        }
    }

    &.dark-btn {
        background: $secondary;
        border-color: $secondary;
        color: #fff;

        &:hover,
        &:focus {
            background: $theme-color;
            border-color: $theme-color;
            color: #fff;
        }
    }
}

.icon-btn {
    padding: 8px 24px 8px 8px;
    width: 100%;
    font-size: 14px;
    line-height: 28px;
    font-weight: 600;
    color: var(--font-color);
    background: no-repeat right 8px center / 24px auto;
    border: 1px solid var(--light-bg);
    border-radius: 8px;
    text-align: left;

    &.arrow-btn {
        background-image: url(../images/icon/right-arrow.svg);
    }

    &.star-btn {
        background-image: url(../images/icon/star-icon.svg);
    }

    &.close-btn {
        background-image: url(../images/icon/close-icon.svg);
    }

    &.light-theme-btn {
        background-color: var(--theme-light);
        border-color: var(--theme-light);
    }

    .btn-list & {
        margin-bottom: 16px;
    }
}

/* Dropdown CSS */
.dropdown-toggle {
    display: inline-flex;

    &::after {
        display: inline-block;
        margin-left: 0.255em;
        vertical-align: 0.255em;
        content: "";
        border-top: 0.3em solid;
        border-right: 0.3em solid transparent;
        border-bottom: 0;
        border-left: 0.3em solid transparent;
        top: 7px;
        position: relative;
    }

    &[aria-expanded="true"]::after {
        @include transform(rotate(180deg));
    }
}

.dropdown-menu {
    padding: 0px 14px;
    background: var(--dropDown-color);
    font-size: 13px;
    line-height: 17px;
    font-weight: 600;
    border: none;
    @include box-shadow(0 10px 20px rgba(0, 0, 0, 0.24));
    border-radius: 0 0 8px 8px;
    overflow: hidden;
    text-transform: capitalize;

    button {
        width: 100%;
        background: transparent;
        border: none;
        display: flex;
        display: -webkit-flex;
        @include justify-content(space-between);

        &:hover,
        &:focus {
            color: inherit;
        }
    }

    .dropdown-item {
        padding: 16px 0px;
        font-weight: inherit;
        color: var(--font-color);
        border-bottom: 1px solid var(--border-light);

        &:last-child {
            border: none;
        }

        &.active-item,
        &:hover {
            background: transparent;
            color: var(--theme-color-light);
        }
    }

    &.user-dropdown {
        min-width: 220px;
    }
}

/* Common Box CSS */
.main-content {
    position: relative;
    min-height: calc(100vh - 320px);

    [data-light-mode="false"] & {
        background: var(--background-miniScoreCard-gradient);
    }

    &::before {
        @extend %after-before;
        width: 100%;
        height: 330px;
        background: var(--background-miniScoreCard-gradient);
        top: 0;
        left: 0;
        z-index: -1;
    }
}

.common-box {
    margin-bottom: 24px;
    padding: 16px;
    background: var(--light-mode-bg);
    border-radius: 16px;
    border: 1px solid var(--border-light);

    > :last-child {
        margin-bottom: 0;
    }
}

.common-height {
    min-height: 400px;
}

/* Widget CSS */
.widget {
    margin-bottom: 30px;

    &:last-child {
        margin-bottom: 30px;
    }
}

.sticky_ads{
    position: sticky !important;
    top: 70px;
}

.card-footer-note {
    margin: 15px 0px 4px;
    font-size: 12px;
    line-height: 17px;
}

.table-footer-note {
    margin: -5px 0 0;
    font-size: 12px;
    line-height: 17px;
}

.sticky-ads {
    margin-bottom: 8px;
    position: sticky;
    position: -webkit-sticky;
    top: 70px;
}

/* Grid CSS */
.row-8 {
    margin: 0px -8px;

    >[class*="col"] {
        padding: 0px 8px;
    }
}

.row-4 {
    margin: 0px -4px;

    >[class*="col"] {
        padding: 0px 4px;
    }
}

.equal-height-article article {
    height: calc(100% - 30px);
}

.twitter-tweet {
    margin: 0px auto !important;
}

.instagram-media {
    margin: 0px auto 24px !important;
}

.article-details-page {
    iframe {
        min-height: 360px;
    }

    .videoWrapper iframe,
    .instagram-media,
    .twitter-tweet iframe,
    [id*="video-ads"],
    [id*="div-ad"] iframe {
        min-height: initial;
    }
}

.also-read-title {
    margin-bottom: 16px;
    color: $theme-color;
    text-transform: uppercase;
    font-weight: 600;
}

.similar-posts {
    margin: 0px -8px 16px;
    display: flex;
    display: -webkit-flex;

    .post-img {
        margin: 0 8px 0 0;
        width: 120px;
        @include flex-shrink(0);

        img {
            margin: 0;
            border-radius: 8px;
        }
    }

    .col {
        margin: 0 8px 8px;
        width: calc(50% - 16px);
        padding: 8px;
        border: 1px solid $light;
        display: flex;
        display: -webkit-flex;
        border-radius: 12px;
        @include align-items(flex-start);
        flex: 0px 1 1;

        p {
            margin: 0;
            font-size: 13px;
            line-height: 18px;
        }
    }

    h6 {
        margin-bottom: 6px;
        font-size: 14px;
        line-height: 18px;
        font-weight: 700;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
}

.trc_related_container .video-label {
    color: var(--font-color-light) !important;
}

/* Track */
// ::-webkit-scrollbar-track { border-radius: 3px; background: var(--light-mode-bg); }
/* Handle */
// ::-webkit-scrollbar-thumb { background: $font-light2; border-radius: 3px; }
/* Handle on hover */
// ::-webkit-scrollbar-thumb:hover { background: $secondary; }

// Spinner CSS
.spinner-border {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    border: 0.2em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    -webkit-animation: 0.75s linear infinite spinner-border;
    animation: 0.75s linear infinite spinner-border;

    &.spinner-border-md {
        width: 2rem;
        height: 2rem;
        border-width: 0.25em;
    }
}

.isDesk {
    display: block;
}

.isMob {
    display: none;
}

.w-0 {
    width: 0 !important;
}

.cursor-pointer {
    cursor: pointer;
}

.text-none {
    text-transform: none !important;
}

@keyframes spinner-border {
    to {
        transform: rotate(360deg);
    }
}

/***** Responsive CSS Start ******/

@media (min-width: 992px) and (max-width: 1400px) {
    .common-sidebar:not(.bt-width) {
        width: calc(310px + calc(var(--bs-gutter-x) * 1));
    }

    .left-content {
        width: calc(100% - (310px + var(--bs-gutter-x) * 1));
    }
}

@media (min-width: 1200px) and (max-width: 1399px) {
    h1 {
        font-size: 30px;
        line-height: 40px;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    h1 {
        margin: 0 0 12px;
        font-size: 28px;
        line-height: 38px;
    }

    h2 {
        margin: 0 0 12px;
        font-size: 26px;
        line-height: 34px;
    }

    h3 {
        margin: 0 0 12px;
        font-size: 22px;
        line-height: 30px;

        &.small-head {
            font-size: 17px;
            line-height: 23px;
        }
    }

    h4,
    .h4 {
        margin: 0 0 12px;
        font-size: 19px;
        line-height: 28px;

        &.small-head {
            font-size: 14px;
            line-height: 18px;
        }
    }

    h5 {
        margin: 0 0 12px;
        font-size: 17px;
        line-height: 26px;

        &.small-head {
            font-size: 14px;
            line-height: 19px;
        }
    }

    h6 {
        margin: 0 0 12px;
        font-size: 17px;
        line-height: 26px;
    }

    .big-text {
        font-size: 15px;
        line-height: 23px;
    }

    .instagram-media {
        margin-bottom: 18px !important;
    }

    .common-box {
        margin-bottom: 20px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    body {
        font-size: 13px;
        line-height: 18px;
    }

    h1 {
        margin: 0 0 10px;
        font-size: 30px;
        line-height: 40px;
    }

    h2 {
        margin: 0 0 10px;
        font-size: 24px;
        line-height: 30px;
    }

    h3 {
        margin: 0 0 10px;
        font-size: 21px;
        line-height: 28px;

        &.small-head {
            font-size: 16px;
            line-height: 22px;
        }
    }

    h4,
    .h4 {
        margin: 0 0 10px;
        font-size: 18px;
        line-height: 27px;

        &.small-head {
            font-size: 14px;
            line-height: 18px;
        }
    }

    h5 {
        margin: 0 0 10px;
        font-size: 16px;
        line-height: 24px;

        &.small-head {
            font-size: 14px;
            line-height: 19px;
        }
    }

    h6 {
        margin: 0 0 10px;
        font-size: 16px;
        line-height: 24px;
    }

    .common-sidebar {
        margin-top: 24px;
    }

    .theme-btn {
        padding: 8px 15px;
        font-size: 14px;
        line-height: 21px;
    }
}

@media (max-width: 767px) {
    h1 {
        margin: 0 0 12px;
        font-size: 28px;
        line-height: 36px;
    }

    h2 {
        margin: 0 0 12px;
        font-size: 22px;
        line-height: 28px;
    }

    h3 {
        margin: 0 0 12px;
        font-size: 22px;
        line-height: 28px;

        &.small-head {
            font-size: 16px;
            line-height: 22px;
        }
    }

    h4,
    .h4 {
        margin: 0 0 12px;
        font-size: 19px;
        line-height: 28px;

        &.small-head {
            font-size: 14px;
            line-height: 18px;
        }
    }

    h5 {
        margin: 0 0 12px;
        font-size: 17px;
        line-height: 26px;

        &.small-head {
            font-size: 14px;
            line-height: 19px;
        }
    }

    h6 {
        margin: 0 0 12px;
        font-size: 17px;
        line-height: 26px;
    }

    .ct-instagram-embed {
        > iframe {
            width: 100%;
        }
    }

    .big-text {
        font-size: 15px;
        line-height: 23px;
    }

    .theme-btn {
        padding: 8px 15px;
        font-size: 14px;
        line-height: 21px;
    }

    /* Common Box CSS */
    .common-section {
        padding: 30px 0;
    }

    .common-box {
        margin-bottom: 20px;
        padding: 12px;
        border-radius: 15px;
    }

    .common-sidebar {
        margin-top: 24px;
    }

    .widget {
        margin-bottom: 24px;
    }

    /* Grid CSS */
    .row {
        margin: 0px -8px;

        >[class*="col"] {
            padding: 0px 8px;
        }
    }

    /* Article CSS */
    .article-grid-list article {
        width: 100%;
    }

    .ml-01 {
        margin-left: 10px !important;
    }
}

@media (max-width: 575px) {
    body {
        font-size: 13px;
        line-height: 18px;
    }

    h1 {
        margin: 0 0 12px;
        font-size: 24px;
        line-height: 30px;
    }

    h2 {
        margin: 0 0 12px;
        font-size: 20px;
        line-height: 25px;
    }

    h3 {
        margin: 0 0 12px;
        font-size: 20px;
        line-height: 26px;

        &.small-head {
            font-size: 15px;
            line-height: 20px;
        }
    }

    h4,
    .h4 {
        margin: 0 0 12px !important;
        font-size: 16px;
        line-height: 22px;
    }

    h5,
    h6 {
        margin: 20px 0 16px;
        font-size: 15px;
        line-height: 22px;
    }

    .big-text {
        font-size: 14px;
        line-height: 22px;
    }

    .line-title {
        margin-bottom: 12px;

        >* {
            padding: 0px 16px;

            &::after {
                left: calc(100% + 16px);
            }

            &::before {
                right: calc(100% + 16px);
            }
        }
    }

    /* Button CSS */
    .theme-btn {
        padding: 8px 12px;
        font-size: 13px;
        line-height: 18px;

        &.xsmall-btn {
            padding: 4px 12px;
        }

        &.iconBtn::after {
            margin-left: 10px;
            width: 20px;
            height: 20px;
        }
    }

    /* Widget CSS */
    .card-footer-note {
        font-size: 11px;
        line-height: 14px;
    }

    /* Grid CSS */
    .row-8 {
        margin: 0px -4px;

        >[class*="col"] {
            padding: 0px 4px;
        }
    }

    .row-4 {
        margin: 0px -4px;

        >[class*="col"] {
            padding: 0px 4px;
        }
    }

    /* Common Box CSS */
    .common-section {
        padding: 24px 0;
    }
}

// Twitter iframe
.twitter_frame {
    overflow: hidden;
}

.twitter_frame iframe {
    margin-top: -18px;
}

.article-details-page {
    iframe {
        min-height: 300px;
    }
}

// Video iframe
.videoWrapper iframe {
    width: 500px;
    height: auto;
    aspect-ratio: 1/0.56;
}

// progress bar
#nprogress {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    width: 100%;
    height: 8px;
    padding: 2px 0;

    .bar {
        background: #fff;
        height: 100%;
    }
}

.shadow-none {
    box-shadow: none;
}

.align-item-center {
    align-items: center !important;
}

.disabled {
    opacity: 0.6;
    pointer-events: none;
}
.content-visibility-auto {
    content-visibility: auto;
}

// Custom slider
.slider.active {
    cursor: grabbing;
    cursor: -webkit-grabbing;
    scroll-behavior: auto !important;
}

.slider-track.active {
    cursor: grabbing;
    cursor: -webkit-grabbing;
    scroll-behavior: auto !important;
}

// Popup ads
#div-gpt-ad-1662037240348-0 {
    display: none;
}

// Sticky ads
#stickyunit {
    z-index: 5;
}

@media (max-width: 767px) {
    #stickyunit {
        bottom: 53px !important;
    }

    #stickyunit>span {
        bottom: auto !important;
    }

    #stickyunit>div {
        bottom: 53px !important;
    }

    .mobileWebView {
        #stickyunit {
            bottom: 0 !important;
        }

        #stickyunit>div {
            bottom: 0 !important;
        }
    }
}

@media (max-width: 991px) {
    .isDesk {
        display: none;
    }

    .isMob {
        display: block;
    }
}

// google one tap login prompt
#g_id_onload {
    position: absolute;
    right: 0;
}
.w-90{
    width: 90%;
}
