$theme-font: 'Manrope', sans-serif;
$theme-color: #F25824;
$theme-dark: #F25824;
$theme-dark2: #F25824;
$theme-medium: #F25824;
$theme-medium2: #ffede6;
$theme-light: #fde2d3;
$font-color: #000000;
$font-dark: #656565;
$font-light: #959595;
$font-light2: #373737;
$secondary: #252775;
$light-bg: #F4F4F4;
$light: #F9F9F9;;
$danger: #DA3400;
$warning: #ff870b;
$success: #14b305;
$success-dark: #006238;
$success-bg: #9AD9BE;
$danger-bg: #ffcccc;
$border-color: #E1E1E1;
$border-dark: #E7E7E7;
$border-dark2: #DCD7D7;
$border-medium: #E0E0E0;
$border-light: #DADADA;
$border-light2: #D8D8D8;
$border-input: #D9D9D9;
$brand-ot11: #F25824;
$brand-dream11: #DB0100;
$brand-11Wickets: #7641A4;
$brand-my11cir: #E42400;
$brand-gamezy: #2400A8;
$light-color: #fff;
$common-transition: all 0.3s ease-in-out;
$theme-gradient: linear-gradient(261.07deg, #a628ec 4.18%, #803be6 107.85%);
